<template>
  <svg :height="`${height}px`" :width="`${width}px`" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.72007 23.932C9.64861 24.2484 9.3386 24.4507 9.02017 24.3887L0.863387 22.8012C0.540023 22.7382 0.327881 22.4264 0.388136 22.1025L0.668066 20.5979C0.729254 20.269 1.04758 20.0537 1.37561 20.1193L7.04664 21.2535C7.20949 21.286 7.36782 21.1801 7.39984 21.0171L9.41444 10.7664C9.4372 10.6506 9.32354 10.5551 9.21341 10.5975L7.2216 11.3635C7.10575 11.4081 7.0293 11.5194 7.0293 11.6435V15.4C7.0293 15.7314 6.76067 16 6.4293 16H5.0043C4.67293 16 4.4043 15.7314 4.4043 15.4V10.1475C4.4043 9.90655 4.54846 9.68896 4.77035 9.595L11.0053 6.95497C11.2274 6.86095 11.4671 6.81574 11.7049 6.77541C11.7483 6.76804 11.794 6.75957 11.8418 6.75C12.0918 6.70833 12.2793 6.6875 12.4043 6.6875C13.2793 6.6875 14.0293 7.125 14.6543 8L15.9668 10.125C17.0811 12.0846 18.7977 13.1407 21.1167 13.2932C21.4474 13.3149 21.7168 13.5811 21.7168 13.9125V15.4C21.7168 15.7314 21.4478 16.0018 21.1168 15.9845C18.5282 15.849 16.3583 14.8258 14.6071 12.9149C14.523 12.8232 14.3684 12.864 14.3437 12.986L13.6252 16.523C13.6044 16.6252 13.6383 16.7309 13.7145 16.802L16.2137 19.1346C16.3353 19.2481 16.4043 19.4069 16.4043 19.5732V28.7125C16.4043 29.0439 16.1357 29.3125 15.8043 29.3125H14.3168C13.9854 29.3125 13.7168 29.0439 13.7168 28.7125V21.4429C13.7168 21.3597 13.6823 21.2803 13.6215 21.2235L11.2865 19.0442C11.1171 18.8861 10.8402 18.9715 10.7891 19.1974L9.72007 23.932ZM16.2168 5.1875C15.7168 5.72917 15.1126 6 14.4043 6C13.696 6 13.071 5.72917 12.5293 5.1875C11.9876 4.64583 11.7168 4.02083 11.7168 3.3125C11.7168 2.60417 11.9876 2 12.5293 1.5C13.071 0.958333 13.696 0.6875 14.4043 0.6875C15.1126 0.6875 15.7168 0.958333 16.2168 1.5C16.7585 2 17.0293 2.60417 17.0293 3.3125C17.0293 4.02083 16.7585 4.64583 16.2168 5.1875Z"
      :fill="`${color}`"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3b82f6'
    },
    width: {
      type: String,
      default: '22'
    },
    height: {
      type: String,
      default: '30'
    }
  }
}
</script>
